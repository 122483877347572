<template>
  <transition
    :appear="appear"
    enter-class="opacity-0"
    leave-to-class="opacity-0"
    enter-active-class="transition-opacity"
    leave-active-class="transition-opacity delay-200"
  >
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'CxFadeTransition',

  props: {
    appear: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
